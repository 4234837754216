/* eslint-disable react/react-in-jsx-scope */
import { Modal, notification } from 'antd';
import moment from 'moment';
import { history } from 'umi';
import {
  checkIn,
  checkPrivacy,
  create,
  createTemplate,
  duplicateSurvey,
  editable,
  exportSurvey,
  fetchGeneralInfo,
  fetchGeneralInfoAdmin,
  fetchGeneralInfoSponsor,
  fetchSurveyLink,
  getAllQuestion,
  getExportFile,
  getFeelingQuestion,
  getModifyUser,
  getSurveySpecificMember,
  query,
  submitSurvey,
  updateEmailList,
  updateStatus,
  updateSurvey,
  replyInvitation,
  welcomeSurvey,
  cancelRequest,
  getHistoryPulse,
  doCheckInPulse,
  fetchHistory,
} from '@/services/survey';
import { getLiveStatus } from '@/services/liveInsight';
import { setAuthority, setToken, setUser } from '@/utils/authority';
import { reloadAuthorized } from '@/utils/Authorized';
import { getQueryUrl } from '@/utils/pathTools';
import { getUser } from '../utils/authority';
import { isEmpty } from 'lodash';
import ConfigSite from '@/../config/configSite';

const { SURVEY_TYPE, QUESTION_TYPE, STATUS } = ConfigSite;

const { confirm: modalConfirm } = Modal;

export default {
  namespace: 'survey',

  state: {
    surveys: {
      data: [],
      pagination: {
        current: 1,
        pageSize: ConfigSite.LIMIT,
      },
    },
    dataExport: [],
    feeling: null,
    generalInfo: {},
    surveyLink: {},
    gettingLink: '',
    data: {},
    dataModify: {},
    welcome: null,
    listSurveySpecific: [],
    addUserStatus: {},
    answerFeeling: [],
    status: false,
    slug: null,
    statusDuplicate: false,
    privacyChecked: {},
    listEmail: {},
    remindCheckIn: false,
    unsaved: true,
    invitationState: {},
    submitError: null,
    listHistory: [],
    historyPagination: {},
    isConsensus: false,
    listQuestionHistoryPulse: [],
    branching: [],
    questionsList: [],
    questionBeforeBranching: [],
  },

  effects: {
    *startNewSurvey(action, { call, put }) {
      const { slug, pulseId, allowAnonymous } = action.payload;
      const user = JSON.parse(getUser());
      try {
        if (isEmpty(user)) {
          return yield put({
            type: 'startSurvey',
            payload: { slug },
          });
        }

        const res = yield call(getLiveStatus, pulseId);

        if (!res.isSuccess)
          return notification.error({
            message: 'Some thing went wrong',
          });

        if (res?.data?.isLive && allowAnonymous !== 1) return history.push('./live');

        return yield put({
          type: 'survey/startSurvey',
          payload: { slug },
        });
      } catch (error) {
        return notification.error({
          message: 'Some thing went wrong',
        });
      }
    },
    *fetch({ payload }, { call, put, select }) {
      const { id: collectionID } = yield select(({ collection }) => collection.data);
      const { params } = payload;
      const response = yield call(query, collectionID, params || null);
      if (response.isSuccess) {
        yield put({
          type: 'append',
          payload: {
            meta: response.meta,
            data: response.data,
          },
        });
        yield put({
          type: 'appendGeneralInfo',
          payload: response.data,
        });
      }
    },
    *create({ payload }, { call }) {
      try {
        const { body, isAddQuestion, collectionID } = payload;
        const res = yield call(create, collectionID, body);
        if (!res.isSuccess) {
          return notification.error({ message: 'Failed to create Pulse' });
        }

        if (body?.pulseType === ConfigSite.PULSE_TYPE.CHECK_IN) {
          history.replace(`/admin/collection/${collectionID}`);
        }

        if (isAddQuestion && body?.pulseType !== ConfigSite.PULSE_TYPE.CHECK_IN) {
          history.replace(`${res?.data?.slug}/edit`);
        }
        return notification.success({ message: 'Create pulse successfully' });
      } catch (error) {
        notification.error({ message: 'Failed to create Pulse' });
        return false;
      }
    },
    *fetchPulseHistory({ payload }, { call, put }) {
      try {
        const res = yield call(fetchHistory, payload.slug);
        if (res.isSuccess) {
          const { detailedCompletedPulse } = res?.data[0];
          const { pulseQuestions } = detailedCompletedPulse || [];
          if (res.meta.ok) {
            yield put({
              type: 'appendHistoryPulse',
              payload: {
                listQuestionHistoryPulse: pulseQuestions,
              },
            });
          } else {
            yield put({
              type: 'appendHistoryPulse',
              payload: {
                listQuestionHistoryPulse: [],
              },
            });
          }
        } else {
          yield put({
            type: 'appendHistoryPulse',
            payload: {
              listQuestionHistoryPulse: [],
            },
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    *updateSurveyInfo({ payload }, { call, put }) {
      const { slug, body } = payload;
      const res = yield call(updateSurvey, slug, body);
      if (res.isSuccess) {
        yield put({
          type: 'setUnsaved',
          payload: { unsaved: false },
        });
      } else {
        notification.error({ message: res.getErrorMessage('Failed to update Pulse') });
      }
    },
    *generalInfo(action, { call, put, select }) {
      const res = yield call(fetchGeneralInfo, action.payload);
      if (res.isSuccess) {
        yield put({
          type: 'appendGeneralInfo',
          payload: res.data,
        });

        const { id: collectionID, title: collectionTitle } = yield select(
          ({ collection }) => collection.data
        );
        yield put({
          type: 'collection/updateBreadcrumbs',
          payload: {
            data: [
              { title: 'Collection list', href: '/admin/collection' },
              {
                title: `Collection: ${collectionTitle}`,
                href: `/admin/collection/${collectionID}`,
              },
              { title: `Pulse: ${res.data.surveyTitle}`, href: '#', disabled: true },
            ],
          },
        });
      } else {
        // TODO: Check why request does not show any error
        notification.error({
          message: 'Error',
          description: res.getErrorMessage() || 'Can not found pulse',
        });
        history.push('/admin'); // Redirect to homepage if code is invalid
      }
    },
    *sponsorGeneralInfo({ payload }, { call, put }) {
      const response = yield call(fetchGeneralInfoSponsor, payload);

      if (response.isSuccess) {
        yield put({
          type: 'appendGeneralInfo',
          payload: response.data,
        });
      } else {
        // TODO: Check why request does not show any error
        notification.error({
          message: 'Error',
          description: response.meta?.message,
        });
      }
    },
    *surveyLink(action, { call, put }) {
      yield put({
        type: 'appendGettingLink',
        payload: action.payload,
      });

      const res = yield call(fetchSurveyLink, action.payload);
      if (res.isSuccess) {
        yield put({
          type: 'appendSurveyLink',
          payload: res.data,
        });
        yield put({
          type: 'appendGettingLink',
          payload: '',
        });
      }
    },
    *update({ payload }, { call, put, select }) {
      const { slug, body, next } = payload;
      const res = yield call(updateSurvey, slug, body);
      if (res.isSuccess) {
        const { id } = yield select(({ collection }) => collection.data);

        yield put({
          type: 'collection/details',
          payload: id,
        });
        notification.success({ message: 'Updated pulse successfully' });
        if (typeof next === 'function') {
          next();
        }
      }
    },
    *updateGeneralInfo({ payload }, { call, put }) {
      const { slug, body } = payload;
      const res = yield call(updateSurvey, slug, body);
      if (res.isSuccess) {
        const res1 = yield call(fetchGeneralInfoAdmin, res.data.slug);
        // !check it using response
        if (res1.isSuccess) {
          yield put({
            type: 'appendGeneralInfo',
            payload: res.data,
          });
        }
      }
    },

    *welcome(action, { call, put }) {
      const res = yield call(welcomeSurvey, action.payload);
      if (res.isSuccess) {
        const { data } = res;
        yield put({
          type: 'appendWelcome',
          payload: data,
        });
        yield put({
          type: 'toggleRemindCheckInModal',
          payload: data.remindCheckIn,
        });
      }
    },
    *submit({ payload }, { call, put }) {
      const { code, data } = payload;
      const res = yield call(submitSurvey, code, data);
      if (res.isSuccess) {
        yield put({
          type: 'appendSubmit',
          payload: res.data,
        });
        history.push(`./success`);
      } else {
        // Check if the Pulse is close
        if (res.meta && res.meta?.errorCode === 6) {
          history.push(`./closedPulse`);
        } else {
          history.push(`./submitFail`);
        }
      }
    },
    *export(action, { call, put }) {
      const res = yield call(exportSurvey, action.payload);
      if (res.isSuccess) {
        yield put({
          type: 'appendExport',
          payload: res.data,
        });
      }
    },
    *getExportFile(action, { call }) {
      const response = yield call(getExportFile, action.payload.slug);
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      const link = document.createElement('a');
      // eslint-disable-next-line compat/compat
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', 'file.xlsx');
      document.body.appendChild(link);
      link.download = `${action.payload.title}.xlsx`;
      link.click();
      document.body.removeChild(link);
    },

    *duplicate(action, { call, put }) {
      const res = yield call(duplicateSurvey, action.payload);
      if (res.isSuccess) {
        yield put({
          type: 'fetch',
          payload: getQueryUrl(),
        });
        yield put({
          type: 'appendDuplicateStatus',
          payload: true,
        });
      }
    },

    *getFeeling(action, { call, put }) {
      // TODO: Remove when needed
      const result = yield call(getFeelingQuestion, action.payload);
      if (result.isSuccess) {
        yield put({
          type: 'appendFeeling',
          payload: result.data,
        });
      } else {
        notification.error({ message: result.getErrorMessage('Failed to get feeling question') });
      }
    },

    *startSurvey(action, { call, put }) {
      const { slug } = action.payload;
      const result = yield call(getFeelingQuestion, slug);
      if (result.isSuccess) {
        yield put({
          type: 'appendFeeling',
          payload: result.data,
        });

        if (result?.data?.isRequired) {
          history.push(`feeling`);
        } else {
          history.push(`question`);
        }
      } else {
        notification.error({ message: result.getErrorMessage('Failed to get feeling question') });
      }
    },
    *startRegisterSurvey({ payload }, { call, put }) {
      const { slug, body, anonymous } = payload;
      const resultQuestion = yield call(getAllQuestion, slug);
      if (resultQuestion.isSuccess) {
        if (resultQuestion?.data?.questions.length > 0) {
          const result = yield call(getFeelingQuestion, slug);
          if (result.isSuccess) {
            yield put({
              type: 'appendFeeling',
              payload: result.data,
            });

            if (result?.data?.required) {
              history.push(`feeling`);
            } else {
              history.push(`question`);
            }
          } else {
            notification.error({
              message: result.getErrorMessage('Failed to get feeling question'),
            });
          }
        } else {
          // submit pulse with no question
          const res = yield call(submitSurvey, body);
          if (res.isSuccess) {
            history.push(`/${slug}/do-pulse-with/${anonymous}/success`);
          }
          return res;
        }
      }
      return resultQuestion;
    },

    *getQuestion(action, { call, put }) {
      const res = yield call(getAllQuestion, action.payload);
      if (res.isSuccess) {
        const { questions: listQuestions } = res.data;
        let questions = listQuestions.map(question => {
          const q = { ...question, pulselyOption: question?.options };
          delete q.options;
          return q;
        });
        let branching = [];
        const brachingIndex = questions?.findIndex(
          question => question.questionType === QUESTION_TYPE.BRANCHING
        );
        if (brachingIndex !== -1) {
          const questionsAfterBranching = questions.slice(brachingIndex + 1);
          questions = questions.slice(0, brachingIndex + 1);
          branching = questions[questions.length - 1]?.pulselyOption.map(item => {
            const branchData = questionsAfterBranching?.filter(ques => {
              return (
                ques.scenario === item.optionTitle || ques.scenario === null || ques.scenario === ''
              );
            });
            return {
              optionId: item.optionId,
              branch: item.optionTitle,
              data: branchData,
            };
          });
        }
        yield put({
          type: 'appendQuestion',
          payload: res?.data,
        });
        yield put({
          type: 'appendQuestionsList',
          payload: questions,
        });
        yield put({
          type: 'appendQuestionsBeforeBranching',
          payload: questions,
        });
        yield put({
          type: 'appendBranching',
          payload: branching,
        });
      } else {
        notification.error({ message: res.getErrorMessage('Failed to get questions') });
      }
    },

    *getModifyUser(action, { call, put }) {
      const res = yield call(getModifyUser, action.payload);
      if (res.isSuccess) {
        yield put({
          type: 'appendModifyUser',
          payload: res.data,
        });
      } else {
        notification.error({ message: res.getErrorMessage('Failed to get users') });
      }
    },
    *getSurveyMember(_, { call, put }) {
      const res = yield call(getSurveySpecificMember);

      if (res.isSuccess) {
        yield put({
          type: 'appendSpecificList',
          payload: res.data.listPulses,
        });
      } else {
        notification.error({ message: res.getErrorMessage('Something went wrong') });
      }
    },
    *checkPrivateCode(action, { call, put }) {
      const res = yield call(checkPrivacy, action.payload?.pulseId);
      if (res.isSuccess) {
        const { data } = res;
        yield put({
          type: 'appendPrivacy',
          payload: data,
        });
        if (data.surveyType === SURVEY_TYPE.PRIVACY && data.isMatch) {
          history.push(`./privacy`);
        }
      }
    },
    *checkPrivateCodeModal(action, { call, put }) {
      const res = yield call(checkPrivacy, action.payload?.pulseId);
      if (res.isSuccess) {
        yield put({
          type: 'appendPrivacy',
          payload: res.data,
        });
      }
    },
    *updateEmailList({ payload }, { call }) {
      const { slug, body } = payload;
      yield call(updateEmailList, slug, body);
    },
    *checkIn(action, { call, put }) {
      const res = yield call(checkIn, action.payload);
      if (!res.isSuccess) {
        notification.error({
          message: 'Error',
          description: "Pulse isn't exist",
        });
        return;
      }
      const { data } = res;
      if (data.isRegistered) {
        yield setToken(data.token);
        yield setAuthority(data.user.userType);
        yield setUser(data.user);
        yield reloadAuthorized();
        if (action?.payload?.redirectTo === 'startSurvey') {
          yield put({
            type: 'startSurvey',
            payload: data.slug,
          });
        } else history.push(`/${data.slug}`);
      } else {
        history.push(`/checkIn/${data.slug}/error`);
      }
    },
    *createTemplate({ payload: slug }, { call }) {
      const response = yield call(createTemplate, slug);
      if (response.isSuccess) {
        const { templateSurveyId } = response.data;
        history.push(`/template/${templateSurveyId}/preview`);
      } else {
        notification.error({
          message: response.getErrorMessage('Failed to create template'),
        });
      }
    },
    *checkEditable({ payload }, { call, select }) {
      const { next, dispatch, collection } = payload;
      const { slug, updatedAt } = yield select(({ survey }) => survey.generalInfo);
      const response = yield call(editable, slug, updatedAt);
      if (!response.isSuccess) {
        const { data } = response;
        if (data) {
          const { lastUpdatedAt, lastUpdatedBy } = data;
          const timeEdited =
            (data.lastUpdatedAt && moment(parseInt(lastUpdatedAt, 10)).format('LLL')) || null;
          let content = '';
          if (lastUpdatedBy) {
            content = `${lastUpdatedBy} has edited this survey${
              timeEdited ? ` at ${timeEdited}.` : '.'
            }`;
          } else {
            content = `This pulse was edited${timeEdited ? ` at ${timeEdited}.` : '.'}`;
          }
          modalConfirm({
            content,
            okText: 'Fetch new contents',
            cancelText: 'Force edit',
            onCancel: next,
            onOk: () => {
              dispatch({
                type: 'collection/details',
                payload: collection.id,
              });
              dispatch({
                type: 'question/fetch',
                payload: { params: slug },
              });
              dispatch({
                type: 'survey/generalInfo',
                payload: slug,
              });
            },
          });
        }
      } else {
        next();
      }
    },
    *clearGeneralInfo(_, { put }) {
      yield put({
        type: 'removeGeneralInfo',
      });
    },

    *replyInvitation({ payload }, { call, put }) {
      const response = yield call(replyInvitation, payload);
      if (response.isSuccess) {
        yield put({
          type: 'appendInvitationState',
          payload: {
            ...response.data,
          },
        });
        history.push(`success`);
      } else {
        notification.error({ message: response.getErrorMessage('Fail to reply the invitation') });
      }
    },
    *updateStatus({ payload }, { call, put }) {
      const { pulseId, status, next } = payload;
      try {
        const response = yield call(updateStatus, pulseId, status);

        if (!response?.meta?.ok) {
          return notification.error({
            message: 'Failure to change status of Pulse',
          });
        }

        if (payload?.inSummaryPage) {
          yield put({
            type: 'generalInfo',
            payload: pulseId,
          });

          if (payload?.status === STATUS.RUNNING) {
            yield put({
              type: 'liveInsight/getQuestion',
              payload: pulseId,
            });
          }
        }

        notification.success({
          message: 'Change pulse status successfully',
        });
        if (next) {
          next();
        }
      } catch (error) {
        notification.error({
          message: 'Failure to change stage of Pulse',
        });
      }
    },
    *cancelRequest({ payload }, { call, put }) {
      const { pulseId } = payload;
      const response = yield call(cancelRequest, pulseId);
      if (response.isSuccess) {
        yield put({
          type: 'getSurveyMember',
          payload: {
            filterType: 'new',
          },
        });
        history.push(`success`);
      } else {
        notification.error({
          message: 'Some thing went wrong, please try later',
        });
      }
    },
    *getHistoryList({ payload }, { call, put }) {
      const response = yield call(getHistoryPulse, payload);
      if (response.isSuccess) {
        yield put({
          type: 'appendListHistory',
          payload: {
            listHistory: response.data.pulses,
            historyPagination: response.data.pagination,
          },
        });
      } else {
        notification.error({
          message: 'Some thing went wrong, Can not get history',
        });
        history.push('/');
      }
    },
    *doCheckInPulse({ payload }, { call }) {
      const response = yield call(doCheckInPulse, payload.checkinCode);
      if (response.isSuccess) {
        history.push(`success`);
      } else {
        history.push(`submitFail`);
      }
    },
  },
  reducers: {
    append(state, action) {
      const {
        payload: { data, meta },
      } = action;
      return {
        ...state,
        surveys: {
          pagination: {
            total: meta.total || 0,
            current: meta.offset / ConfigSite.LIMIT + 1,
          },
          data: data || [],
        },
        status: true,
      };
    },
    appendQuestionsList(state, action) {
      return {
        ...state,
        questionsList: action.payload,
      };
    },
    appendQuestionsBeforeBranching(state, action) {
      return {
        ...state,
        questionBeforeBranching: action.payload,
      };
    },
    appendBranching(state, action) {
      return {
        ...state,
        branching: action.payload,
      };
    },
    appendDuplicateStatus(state, action) {
      return {
        ...state,
        statusDuplicate: action.payload,
      };
    },
    saveAnswers(state, action) {
      return {
        ...state,
        answers: action.payload.answers,
      };
    },

    appendFeeling(state, action) {
      return {
        ...state,
        feeling: action.payload,
      };
    },

    updateSlug(state, action) {
      return {
        ...state,
        slug: action.payload.slug,
      };
    },
    updateSurveys(state, action) {
      return {
        ...state,
        surveys: {
          ...state.surveys,
          data: [...action.payload],
        },
      };
    },
    updateSurveyStatus(state, action) {
      return {
        ...state,
        generalInfo: {
          ...state.generalInfo,
          surveyStatus: action.payload,
        },
      };
    },
    appendWelcome(state, action) {
      return {
        ...state,
        welcome: action.payload,
      };
    },
    appendSubmit(state, action) {
      return {
        ...state,
        trash: true,
        submit: action,
      };
    },

    toggleIsConsensus(state, action) {
      return {
        ...state,
        isConsensus: action.payload,
      };
    },

    appendPreviewData(state, action) {
      return {
        ...state,
        previewData: action.payload,
      };
    },
    appendExport(state, action) {
      return {
        ...state,
        dataExport: action.payload,
      };
    },

    appendGeneralInfo(state, action) {
      const { payload } = action;
      return {
        ...state,
        slug: payload?.slug,
        generalInfo: { ...payload },
      };
    },

    appendSurveyLink(state, action) {
      return {
        ...state,
        surveyLink: action.payload,
        pulseType: action.payload.pulseType,
      };
    },
    appendGettingLink(state, action) {
      return {
        ...state,
        gettingLink: action.payload,
      };
    },

    appendQuestion(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },

    appendModifyUser(state, action) {
      return {
        ...state,
        dataModify: action.payload,
      };
    },
    appendSpecificList(state, action) {
      return {
        ...state,
        listSurveySpecific: action.payload,
      };
    },
    submitFeeling(state, action) {
      return {
        ...state,
        answerFeeling: [action.payload],
      };
    },
    appendPrivacy(state, action) {
      return {
        ...state,
        privacyChecked: action.payload,
      };
    },
    appendEmail(state, action) {
      return {
        ...state,
        listEmail: action.payload,
      };
    },
    appendInvitationState(state, action) {
      return {
        ...state,
        invitationState: {
          ...action.payload,
        },
      };
    },
    setUnsaved(state, { payload }) {
      return {
        ...state,
        unsaved: payload.unsaved,
      };
    },
    toggleRemindCheckInModal(state, action) {
      return {
        ...state,
        remindCheckIn: action.payload,
      };
    },
    removeGeneralInfo(state) {
      return {
        ...state,
        slug: null,
        generalInfo: {},
      };
    },
    appendListHistory(state, { payload }) {
      return {
        ...state,
        listHistory: [...state.listHistory, ...payload.listHistory],
        historyPagination: payload.historyPagination,
      };
    },
    clearHistoryList(state) {
      return {
        ...state,
        listHistory: [],
        historyPagination: {},
      };
    },
    appendPulse(state, { payload }) {
      const newPulses = [...state.surveys.data];
      newPulses.push(payload);
      return {
        ...state,
        surveys: {
          ...state.surveys,
          data: newPulses,
        },
      };
    },
    appendHistoryPulse(state, { payload }) {
      return { ...state, listQuestionHistoryPulse: payload.listQuestionHistoryPulse };
    },
  },
  subscriptions: {
    listenUrlChanged({ history: historyListen, dispatch }) {
      return historyListen.listen(({ pathname }) => {
        const testUrl = /\/admin\/collection\/[0-9]+\/pulse\/\w+\//;
        const userUrl = /^(?!\/admin)(\/\w+)\/\w+/;
        if (testUrl.test(pathname) && !pathname.includes('statistic')) {
          const slug = pathname
            .match(testUrl)
            .join('')
            .split('/')
            .filter(s => s)
            .pop();
          dispatch({ type: 'generalInfo', payload: slug });
        }
        if (
          userUrl.test(pathname) &&
          !pathname.includes('login') &&
          !pathname.includes('statistic')
        ) {
          const slug = pathname
            .match(userUrl)
            .join('')
            .split('/')
            .filter(s => s)[0];
          if (slug.length > 0) {
            dispatch({ type: 'sponsorGeneralInfo', payload: slug });
          }
        }
      });
    },
  },
};
