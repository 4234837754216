import { addQuestion, queryDetail } from '@/services/question';
import { notification } from 'antd';

function* createFeelingQuestion({ payload }, { call, put }) {
  const { question, pulseId } = payload;
  try {
    const response = yield call(addQuestion, pulseId, question);

    if (!response?.isSuccess) {
      return notification.error({
        message: 'Failed to add question',
        description: response?.meta?.message,
      });
    }

    yield put({
      type: 'appendFeeling',
      payload: { ...response?.data },
    });

    const responseDetail = yield call(queryDetail, pulseId);
    if (!responseDetail.isSuccess) return notification.error({ message: 'Something went wrong' });

    const dataDetail = responseDetail.data;
    yield put({
      type: 'survey/appendGeneralInfo',
      payload: { ...dataDetail?.generalInfo },
    });

    return true;
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
}

export default createFeelingQuestion;
