import { cloneDeep } from 'lodash';

export default function updateOthersAnswerOfQuestionInList(
  state: QuestionModel.QuestionModelState,
  { payload }: { payload: any }
) {
  // Suspicious
  const { list } = state;

  const newList = cloneDeep(list);

  const index = newList.findIndex(el => el.questionId === payload.questionId);

  if (!payload?.questionId) return;

  if (
    payload.add &&
    !newList[index]?.pulselyOption?.some(option => option.optionTitle === 'Others')
  ) {
    newList?.[index].pulselyOption?.push({
      isOptionText: 1,
      optionId: 0,
      optionTitle: 'Others',
      optionType: 1,
    });
  } else {
    newList?.[index].pulselyOption?.pop();
  }

  return { ...state, list: newList };
}
