// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/opt/build/repo/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/register",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__RegisterCheckIn' */'/opt/build/repo/src/pages/UserViewSurvey/RegisterCheckIn'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/forgot-password",
    "name": "Forgot password",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ForgotPassword' */'/opt/build/repo/src/pages/ForgotPassword'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/export-pdf",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Summary__SummaryTab__ExportPDF' */'/opt/build/repo/src/pages/Summary/SummaryTab/ExportPDF'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/login",
    "routes": [
      {
        "name": "Login successful",
        "path": "/login/successful",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LoginPage__Successful' */'/opt/build/repo/src/pages/LoginPage/Successful.js'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Login External User",
        "path": "/login/user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LoginPage__User' */'/opt/build/repo/src/pages/LoginPage/User'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LoginPage__User' */'/opt/build/repo/src/pages/LoginPage/User'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "name": "Smart Link",
    "path": "/smart-link/:slug",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLinkPage' */'/opt/build/repo/src/pages/SmartLinkPage'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "name": "setup password",
    "path": "/setup-password/:token",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SetupPassword' */'/opt/build/repo/src/pages/SetupPassword'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "name": "Live Insight",
    "path": "/live-answer/:pulseId",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/opt/build/repo/src/pages/Authorized'), loading: LoadingComponent})],
    "authority": [
      "user",
      "admin"
    ],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LiveInsight' */'/opt/build/repo/src/pages/LiveInsight'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/admin",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__PulselyLayout' */'/opt/build/repo/src/layouts/PulselyLayout'), loading: LoadingComponent}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/opt/build/repo/src/pages/Authorized'), loading: LoadingComponent})],
    "authority": [
      "admin",
      "user"
    ],
    "routes": [
      {
        "path": "/admin/collection",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AdminCollection__index' */'/opt/build/repo/src/pages/AdminCollection/index.js'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/admin/collection/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AdminCollection__CollectionDetails' */'/opt/build/repo/src/pages/AdminCollection/CollectionDetails.js'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AdminCollection__Collection__index' */'/opt/build/repo/src/pages/AdminCollection/Collection/index.tsx'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/admin/library",
        "routes": [
          {
            "path": "/admin/library/pulse-template/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PulseTemplate' */'/opt/build/repo/src/pages/PulseTemplate'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourcesLibrary' */'/opt/build/repo/src/pages/ResourcesLibrary'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/admin/pulses/:pulseId/statistic",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Summary__index' */'/opt/build/repo/src/pages/Summary/index.js'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/admin/pulses/:slug/history",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HistorySurvey' */'/opt/build/repo/src/pages/HistorySurvey'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/admin/pulses",
        "routes": [
          {
            "path": "/admin/pulses/:id",
            "routes": [
              {
                "path": "/admin/pulses/:id/question",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SinglePulse__CreateQuestion' */'/opt/build/repo/src/pages/SinglePulse/CreateQuestion'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SinglePulse__Details' */'/opt/build/repo/src/pages/SinglePulse/Details'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SinglePulse' */'/opt/build/repo/src/pages/SinglePulse'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/admin/pulse",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SinglePulse__CreatePulse' */'/opt/build/repo/src/pages/SinglePulse/CreatePulse'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "redirect": "/admin/pulses",
        "exact": true
      },
      {
        "path": "/admin/403",
        "authority": [
          "admin",
          "user"
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ForbiddenPage' */'/opt/build/repo/src/components/ForbiddenPage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ErrorPage' */'/opt/build/repo/src/components/ErrorPage'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/do-pulse/:slug",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__Anonymous__BlankLayoutAnonymous' */'/opt/build/repo/src/layouts/Anonymous/BlankLayoutAnonymous'), loading: LoadingComponent}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/opt/build/repo/src/pages/Authorized'), loading: LoadingComponent})],
    "authority": [
      "user",
      "externalUser"
    ],
    "routes": [
      {
        "path": "/do-pulse/:slug/do-pulse-with",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__Anonymous__AnonymousLogin' */'/opt/build/repo/src/pages/UserViewSurvey/Anonymous/AnonymousLogin'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/do-pulse/:slug/do-pulse-with/:anonymous",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__Anonymous__AnonymousLayout' */'/opt/build/repo/src/layouts/Anonymous/AnonymousLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/do-pulse/:slug/do-pulse-with/:anonymous/submitFail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__component__Screens__FailScreen' */'/opt/build/repo/src/pages/UserViewSurvey/component/Screens/FailScreen'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/do-pulse/:slug/do-pulse-with/:anonymous/closedPulse",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__component__Screens__ClosedPulseScreen' */'/opt/build/repo/src/pages/UserViewSurvey/component/Screens/ClosedPulseScreen'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/do-pulse/:slug/do-pulse-with/:anonymous/question",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__ShowQuestion__index' */'/opt/build/repo/src/pages/UserViewSurvey/ShowQuestion/index.js'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/do-pulse/:slug/do-pulse-with/:anonymous/feeling",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__FeedbackFeeling' */'/opt/build/repo/src/pages/UserViewSurvey/FeedbackFeeling'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/do-pulse/:slug/do-pulse-with/:anonymous/live",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__PulseLive' */'/opt/build/repo/src/pages/UserViewSurvey/PulseLive'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/do-pulse/:slug/do-pulse-with/:anonymous/invited",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__component__Screens__InvitedScreen__InvitedScreen' */'/opt/build/repo/src/pages/UserViewSurvey/component/Screens/InvitedScreen/InvitedScreen.js'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/do-pulse/:slug/do-pulse-with/:anonymous/request-response",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__component__Screens__RequestResponse__RequestResponse' */'/opt/build/repo/src/pages/UserViewSurvey/component/Screens/RequestResponse/RequestResponse.js'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/do-pulse/:slug/do-pulse-with/:anonymous/register",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__component__Screens__RegisterScreen__RegisterScreen' */'/opt/build/repo/src/pages/UserViewSurvey/component/Screens/RegisterScreen/RegisterScreen.js'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/do-pulse/:slug/do-pulse-with/:anonymous/checkin",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__component__Screens__CheckInScreen__CheckInScreen' */'/opt/build/repo/src/pages/UserViewSurvey/component/Screens/CheckInScreen/CheckInScreen.js'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/do-pulse/:slug/do-pulse-with/:anonymous/success",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__component__Screens__SuccessScreen__SuccessScreen' */'/opt/build/repo/src/pages/UserViewSurvey/component/Screens/SuccessScreen/SuccessScreen.js'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "welcome",
            "path": "/do-pulse/:slug/do-pulse-with/:anonymous/welcome",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__WelcomePage' */'/opt/build/repo/src/pages/UserViewSurvey/WelcomePage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ErrorPage' */'/opt/build/repo/src/components/ErrorPage'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ErrorPage' */'/opt/build/repo/src/components/ErrorPage'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/history",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/opt/build/repo/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "authority": [
      "user",
      "externalUser"
    ],
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/opt/build/repo/src/pages/Authorized'), loading: LoadingComponent})],
    "exact": true,
    "routes": [
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__History__History' */'/opt/build/repo/src/pages/UserViewSurvey/History/History.tsx'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/:slug",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/opt/build/repo/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/opt/build/repo/src/pages/Authorized'), loading: LoadingComponent})],
    "authority": [
      "user",
      "externalUser"
    ],
    "routes": [
      {
        "path": "/:slug/review",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__History__HistoryDetail' */'/opt/build/repo/src/pages/UserViewSurvey/History/HistoryDetail.js'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "detail",
        "path": "/:slug/review/:questionId",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__History__QuestionDetail__index' */'/opt/build/repo/src/pages/UserViewSurvey/History/QuestionDetail/index.js'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__WelcomePage__WelcomePage' */'/opt/build/repo/src/pages/UserViewSurvey/WelcomePage/WelcomePage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ErrorPage' */'/opt/build/repo/src/components/ErrorPage'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "authority": [
      "user",
      "externalUser"
    ],
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/opt/build/repo/src/pages/Authorized'), loading: LoadingComponent})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/opt/build/repo/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserViewSurvey__DashBoard__DashBoard' */'/opt/build/repo/src/pages/UserViewSurvey/DashBoard/DashBoard.js'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ErrorPage' */'/opt/build/repo/src/components/ErrorPage'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
