/* eslint-disable compat/compat */
import { message } from 'antd';
import { fetchGeneralInfoAdmin, getExportFileExample } from '@/services/survey';

export const SETTINGS = {
  ANSWER: {
    ONCE: 0,
    MANY: 1,
  },
  AUTO_ACCEPT: {
    NO: 0,
    YES: 1,
  },
  AUTO_CONFIRM: {
    NO: 0,
    YES: 1,
  },
  ALLOW_GUEST: {
    NO: 0,
    YES: 1,
  },
  SENT_TO: {
    INVITED: 0,
    READY: 1,
  },
  FILTER_TYPE: {
    ALL: 0,
    REGISTERED: 1,
    CHECKED_IN: 2,
  },
};

const checkEmailIsExist = (listMember, member) =>
  listMember &&
  Array.isArray(listMember) &&
  listMember.findIndex(m => m.email === member.email) > -1;

const CreatesSurveyModel = {
  namespace: 'CreatesSurveyModel',
  state: {
    visible: false,
    isPrivacy: false,
    surveyEmail: [],
    inputVisible: false,
    bannerLoading: false,
    progressUpload: 0,
    startDate: null,
    endDate: null,
    bannerImage: null,
    bannerImageFile: null,
    title: null,
    description: null,
    surveyId: null,
    surveyStatus: 0,
    isEventSurvey: true,
    pulseType: 0,
    countCheckIn: 0,
    countSubmission: 0,
    sponsorList: null,
    provider: {
      members: [],
      total: 0,
      numberMemberDuplicate: 0,
    },
    collector: {
      members: [],
      total: 0,
      numberMemberDuplicate: 0,
    },
    settings: {
      // isMultiAnswer: SETTINGS.ANSWER.ONCE,
      allowMultiAnswer: SETTINGS.ANSWER.ONCE,
      autoAccept: SETTINGS.AUTO_ACCEPT.YES,
      autoConfirm: SETTINGS.AUTO_CONFIRM.YES,
      filterType: SETTINGS.FILTER_TYPE.ALL,
      purposeType: 0,
      // allowGuest: SETTINGS.ALLOW_GUEST.YES,
      // sendEmailType: SETTINGS.SENT_TO.INVITED,
    },
    activityWebhookUrl: null,
  },
  effects: {
    *setBannerLoading({ payload }, { put }) {
      yield put({ type: 'saveTimeRange', payload });
    },
    *fetchSurveyInfo(action, { call, put }) {
      const response = yield call(fetchGeneralInfoAdmin, action.payload.slug);
      if (response.isSuccess) {
        const { data } = response;
        yield put({
          type: 'saveSurveyInfo',
          payload: data,
        });
        yield put({
          type: 'survey/appendGeneralInfo',
          payload: data,
        });
        yield put({
          type: 'appendCollector',
          payload: data.collectors,
        });
        yield put({
          type: 'appendProvider',
          payload: data.providers,
        });
      } else {
        message.error(response.getErrorMessage('Failed to fetch general info!'));
      }
    },
    *getExportFile(_, { call }) {
      const response = yield call(getExportFileExample);
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', 'file.xlsx');
      document.body.appendChild(link);
      link.download = `template-list-members.xlsx`;
      link.click();
      document.body.removeChild(link);
    },
    *clearSurveyInfo(_, { put, select }) {
      const currentUser = yield select(({ user }) => user.currentUser);
      yield put({
        type: 'generateSurveyInfo',
        payload: { id: 1, name: currentUser.name, email: currentUser.email },
      });
    },
  },
  reducers: {
    saveBannerLoading(state, action) {
      return { ...state, bannerLoading: action.payload.bannerLoading };
    },
    saveRunningTime(state, action) {
      return { ...state, startDate: action.payload.startDate, endDate: action.payload.endDate };
    },
    saveSurveyEmail(state, action) {
      return { ...state, surveyEmail: action.payload.surveyEmail };
    },
    saveIsPrivacy(state, action) {
      return { ...state, isPrivacy: action.payload.isPrivacy };
    },
    saveInputVisible(state, action) {
      return { ...state, inputVisible: action.payload.inputVisible };
    },
    saveBannerImage(state, action) {
      return {
        ...state,
        bannerImage: action.payload.bannerImage,
        bannerImageFile: action.payload.bannerImageFile,
      };
    },
    saveSurveyInfo(state, { payload }) {
      return {
        ...state,
        surveyId: payload.surveyId,
        surveyStatus: payload.surveyStatus,
        title: payload.surveyTitle,
        description: payload.surveyInfo,
        bannerImage: payload.bannerUrl,
        startDate: payload.startDate,
        endDate: payload.endDate,
        isEventSurvey: payload.isEventSurvey,
        pulseType: payload.pulseType,
        countCheckIn: payload.countCheckIn,
        countSubmission: payload.countSubmission,
        settings: payload.settings,
        activityWebhookUrl: payload.activityWebhookUrl,
      };
    },
    generateSurveyInfo(state, { payload }) {
      return {
        ...state,
        title: null,
        description: null,
        bannerImage: null,
        startDate: null,
        endDate: null,
        surveyId: null,
        surveyStatus: 0,
        isEventSurvey: true,
        pulseType: 0,
        countCheckIn: 0,
        countSubmission: 0,
        provider: {
          members: [],
          total: 0,
          numberMemberDuplicate: 0,
        },
        collector: {
          members: [payload],
          total: 0,
          numberMemberDuplicate: 0,
        },
        activityWebhookUrl: null,
      };
    },
    appendCollector(state, { payload }) {
      const newMembers = payload?.map(({ user }, index) => ({
        id: index + 1,
        name: user.name || '',
        email: user.email,
        userId: user.userId,
      }));
      return {
        ...state,
        collector: {
          members: newMembers,
          total: newMembers?.length,
          numberMemberDuplicate: 0,
        },
      };
    },
    appendProvider(state, { payload }) {
      const newMembers = payload?.map(({ user }, index) => ({
        id: index + 1,
        name: user.name || '',
        email: user.email,
        userId: user.userId,
      }));
      return {
        ...state,
        provider: {
          members: newMembers,
          total: newMembers?.length,
          numberMemberDuplicate: 0,
        },
      };
    },
    addMemberToProvider(state, { payload }) {
      const { provider } = state;
      const newMembers = provider.members;
      const isExists = checkEmailIsExist(provider.members, payload);
      if (isExists) {
        provider.numberMemberDuplicate += 1;
        payload.isDuplicate = true;
      }
      newMembers.push({ ...payload, id: newMembers.length + 1 });
      provider.members = newMembers;
      return {
        ...state,
        provider,
      };
    },
    addMemberToCollector(state, { payload }) {
      const { collector } = state;
      const newMembers = collector.members;
      const isExists = checkEmailIsExist(collector.members, payload);
      if (isExists) {
        collector.numberMemberDuplicate += 1;
        payload.isDuplicate = true;
      }
      newMembers.push({ ...payload, id: newMembers.length + 1 });
      collector.members = newMembers;
      return {
        ...state,
        collector,
      };
    },
    removeMemberCollector(state, { payload: { id } }) {
      const { collector } = state;
      const newMembers =
        collector.members
          .filter(member => member.id !== id)
          .map(({ name, email, userId }, index) => ({ id: index + 1, userId, name, email })) || [];
      collector.members = newMembers;
      return {
        ...state,
        collector,
      };
    },
    removeMemberProvider(state, { payload: { id } }) {
      const { provider } = state;
      const newMembers =
        provider.members
          .filter(member => member.id !== id)
          .map(({ name, email, userId }, index) => ({ id: index + 1, userId, name, email })) || [];
      provider.members = newMembers;
      return {
        ...state,
        provider,
      };
    },
    updateMemberCollector(state, { payload }) {
      const { collector } = state;
      const newMembers = collector.members;
      const index = payload.index - 1;
      newMembers[index] = payload;
      collector.members = newMembers;
      return {
        ...state,
        collector,
      };
    },
    updateMemberProvider(state, { payload }) {
      const { provider } = state;
      const newMembers = provider.members;
      const index = payload.index - 1;
      newMembers[index] = payload;
      provider.members = newMembers;
      return {
        ...state,
        provider,
      };
    },
    removeCollectorDuplicate(state) {
      const { collector } = state;
      const newMembers = collector.members.filter(member => !member.isDuplicate);
      collector.members = newMembers;
      collector.numberMemberDuplicate = 0;
      return {
        ...state,
        collector,
      };
    },
    removeProviderDuplicate(state) {
      const { provider } = state;
      const newMembers = provider.members.filter(member => !member.isDuplicate);
      provider.members = newMembers;
      provider.numberMemberDuplicate = 0;
      return {
        ...state,
        provider,
      };
    },
    saveProgressUpload(state, { payload }) {
      return {
        ...state,
        progressUpload: payload,
      };
    },
    saveSettings(state, { payload }) {
      return {
        ...state,
        settings: { ...state.settings, ...payload },
      };
    },
    savePulseType(state, { payload }) {
      return {
        ...state,
        pulseType: payload,
      };
    },
    clearSettings(state) {
      return {
        ...state,
        pulseType: 0,
        settings: {
          allowMultiAnswer: SETTINGS.ANSWER.ONCE,
          autoAccept: SETTINGS.AUTO_ACCEPT.YES,
          autoConfirm: SETTINGS.AUTO_CONFIRM.YES,
          filterType: SETTINGS.FILTER_TYPE.ALL,
          purposeType: 0,
        },
      };
    },
  },
};
export default CreatesSurveyModel;
